<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <AddHeader @submit="submit()" @cancel="addCancel" v-if="operation === 'add'">
          <span slot="otherButton" class="vg_mr_8 flexHV">
            询价单编号:
            <el-input disabled style="width: 150px" size="small" clearable placeholder="保存后生成" />
          </span>
        </AddHeader>
        <EditHeader
          v-if="operation === 'edit'"
          :isShow="isShow"
          :btn="btn"
          :strForm="{ status: formStatus }"
          @openEdit="isShow = false"
          @closeEdit="closeEdit"
          @submit="submit"
          @refresh="init"
        >
          <span slot="otherButton" class="vg_mr_8 flexHV">
            询价单编号:
            <el-input disabled style="width: 150px" v-model="inquiryNumber" size="small" clearable placeholder="请输入" />
          </span>
        </EditHeader>
        <DynamicForm
          v-bind="$attrs"
          :total-width="addForm.totalWidth"
          :split="addForm.split"
          :form-list="addForm.formProperties"
          ref="dynamicForm"
          class="dynamicForm"
          :is-show="isShow"
        />
        <div class="vg_mb_8">
          <el-button :disabled="computedDisabled" icon="el-icon-delete" size="small" type="danger" @click="delSubTableRow()">删除</el-button>
          <el-button :disabled="computedDisabled" size="small" plain @click="dialogVisible1 = true">导入样品核价单</el-button>
          <el-button :disabled="computedDisabled" size="small" plain @click="dialogVisible2 = true">导入二部报价单</el-button>
          <el-button :disabled="computedDisabled" size="small" plain @click="dialogVisible3 = true">导入简易报价单</el-button>
        </div>
        <SearchTable
          ref="multiTable"
          :data="subTableData"
          :tableRowKey="'inquiryProId'"
          :columns="tableProperties"
          v-loading="loadFlag"
          @select="selectRows"
          @selection-change="handleSelectionChange"
          @select-all="selectRows"
          :needPagination="false"
          :needSearch="false"
          :is-show="isShow"
          :computed-data="{ confirmPrice: confirmPrice }"
        >
          <template v-slot:inquiryFactory="scope">
            <el-col :span="20" v-for="(item, index) in scope.row['tempSize']" :key="`tempSize${index}`">
              <div style="display: flex">
                <el-select
                  class="vg_mr_8"
                  size="mini"
                  :disabled="tableProperties[14].subItem.disabled"
                  filterable
                  v-model="scope.row['tempSupp'][index]"
                  placeholder="请选择工厂"
                >
                  <el-option
                    v-for="item in suppList"
                    :key="`tempSize${item.supp_id}`"
                    :label="item.supp_abbr"
                    :value="{ value: item.supp_id, label: item.supp_abbr }"
                  />
                </el-select>
                <el-input
                  size="mini"
                  :disabled="tableProperties[14].subItem.disabled"
                  @input="val => (scope.row['tempPrice'][index] = keepNumber(val))"
                  v-model="scope.row['tempPrice'][index]"
                  clearable
                  placeholder="请填写价格"
                />
              </div>
            </el-col>
            <el-button
              :disabled="componentOtherStatus"
              icon="el-icon-plus"
              circle
              size="mini"
              @click="scope.row['tempSize'] = scope.row['tempSize'] + 1"
            ></el-button>
          </template>
          <template v-slot:confirmFactory="scope">
            <el-select
              size="mini"
              filterable
              :disabled="tableProperties[15].subItem.disabled"
              v-model="scope.row['confirmFactory']"
              placeholder="请确认工厂"
              @change="val => confirmFactory(val, scope.row)"
            >
              <el-option v-for="(item, index) in scope.row['tempSupp']" :key="`confirmPrice${item.value}${index}`" :label="item.label" :value="item.value" />
            </el-select>
          </template>
        </SearchTable>
        <el-row class="vg_mt_16 vd_bortop">
          <el-col>
            <inputUser :isCookie="operation === 'add'" :stffForm="operation === 'edit' ? stffForm : null" ref="userData"></inputUser>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'" lazy v-if="operation === 'edit'">
        <ApprovalFlow :isShow="isShow" @draftState="init" />
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'" v-if="operation === 'edit'">
        <RelatedDocuments ref="relatedDocuments" />
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="样品核价单" top="0" @opened="getDialog1Data" :visible.sync="dialogVisible1" width="70%">
      <div class="vg_mb_8">
        <el-button type="primary" size="small" @click="dialog1Confirm">确认选择</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="dialog1Refresh()"> 刷新</el-button>
      </div>
      <search-table
        ref="multiTable1"
        :data="dialog1TableData"
        :tableRowKey="'dialogVisible1'"
        :columns="dialog1Properties"
        v-loading="dialog1Loading"
        :totalPage="totalPage1"
        :page-size="10"
        :need-fixed-height="true"
        @selection-change="val => (dialog1Selection = val)"
        @getTableData="getDialog1Data"
      >
      </search-table>
    </el-dialog>
    <el-dialog title="二部报价单" top="0" @opened="getDialog2Data" :visible.sync="dialogVisible2" width="70%">
      <div class="vg_mb_8">
        <el-button type="primary" size="small" @click="dialog2Confirm">确认选择</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="dialog2Refresh()"> 刷新</el-button>
      </div>
      <search-table
        ref="multiTable2"
        :data="dialog2TableData"
        :tableRowKey="'dialogVisible2'"
        :columns="dialog2Properties"
        v-loading="dialog2Loading"
        :totalPage="totalPage2"
        :need-fixed-height="true"
        @selection-change="val => (dialog2Selection = val)"
        @getTableData="getDialog2Data"
      >
      </search-table>
    </el-dialog>
    <el-dialog title="简易报价单" top="0" @opened="getDialog3Data" :visible.sync="dialogVisible3" width="70%">
      <div class="vg_mb_8">
        <el-button type="primary" size="small" @click="dialog3Confirm">确认选择</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="dialog3Refresh()"> 刷新</el-button>
      </div>
      <search-table
        ref="multiTable3"
        :data="dialog3TableData"
        :tableRowKey="'dialogVisible3'"
        :columns="dialog3Properties"
        v-loading="dialog3Loading"
        :totalPage="totalPage3"
        :need-fixed-height="true"
        @selection-change="val => (dialog3Selection = val)"
        @getTableData="getDialog3Data"
      >
      </search-table>
    </el-dialog>
  </div>
</template>

<script>
import AddHeader from '@/views/component/addHeader.vue';
import EditHeader from '@/views/component/editHeaderLine.vue';
import ApprovalFlow from '@/views/component/apprCommon/apprMain.vue';
import RelatedDocuments from '@/views/component/docuCommon/docuAbout.vue';
import DynamicForm from '@/components/DynamicFormV2.vue';
import { deleteTableRow } from '@assets/js/arrayUtils';
import { dialog1Properties, dialog2Properties, dialog3Properties, rqutProperties, subTableProperties } from '@/views/DevelopManagement/RqutManage/rqut';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { getNoCatch } from '@api/request';
import { custAPI } from '@api/modules/cust';
import InputUser from '@/views/component/inputUser.vue';
import { rqutAPI } from '@api/modules/rqut';
import { suppAPI } from '@api/modules/supp';
import { keepNumber } from '@assets/js/regExUtil';
import { optnAPI } from '@api/modules/optn';
import { cloneDeep } from 'lodash';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'RqutForm',
  components: {
    InputUser,
    SearchTable,
    EditHeader,
    AddHeader,
    ApprovalFlow,
    RelatedDocuments,
    DynamicForm
  },
  data() {
    return {
      loadFlag: false,
      multiSelection: [],
      btn: {},
      isShow: true,
      activeName: 'first',
      operation: '',
      inquiryNumber: '',
      formStatus: 0,
      addForm: {
        split: 8,
        totalWidth: 24,
        formProperties: cloneDeep(rqutProperties)
      },
      subTableData: [],
      tableProperties: cloneDeep(subTableProperties),
      dialog1Properties: cloneDeep(dialog1Properties),
      dialog2Properties: cloneDeep(dialog2Properties),
      dialog3Properties: cloneDeep(dialog3Properties),
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      stffForm: {},
      subTableMap: new Map(),
      totalPage1: 0,
      totalPage2: 0,
      totalPage3: 0,
      dialog1Loading: false,
      dialog2Loading: false,
      dialog3Loading: false,
      dialog1TableData: [],
      dialog2TableData: [],
      dialog3TableData: [],
      dialog1Selection: [],
      dialog2Selection: [],
      dialog3Selection: [],
      suppList: [],
      unitList: [],
      componentOtherStatus: false
    };
  },
  created() {
    let path = this.$route.path;
    this.operation = path.substring(path.indexOf('_') + 1);
  },
  mounted() {
    this.isShow = this.operation === 'edit';
    this.init();
  },
  computed: {
    computedDisabled() {
      return !(this.operation === 'add' || (this.formStatus === 0 && !this.isShow));
    },
    confirmPrice() {
      return index => {
        let { confirmFactory, tempSupp, tempPrice } = this.subTableData[index];
        let findIndex = tempSupp.findIndex(({ value }) => value === confirmFactory);
        if (findIndex === -1) {
          return '';
        }
        this.subTableData[index].confirmPrice = tempPrice[findIndex];
        return tempPrice[findIndex];
      };
    }
  },
  methods: {
    /* Regex */
    keepNumber,
    async init() {
      await (this.loadFlag = true);
      await this.$refs.dynamicForm.reset();
      await this.getCustomerList();
      await this.getSupp();
      await this.getUnit();
      await this.getTableDataById();
      await (this.loadFlag = false);
    },
    getTableDataById() {
      if (this.operation === 'add') {
        this.tableProperties.forEach((item, index) => {
          if (index > 13) {
            item.subItem.disabled = true;
            this.componentOtherStatus = true;
          }
        });
      } else if (this.operation === 'edit') {
        let id = JSON.parse(UrlEncode.decode(this.$route.query.key)).form_id;
        rqutAPI.getInquirySheetByInquiryId({ rqut_id: id }).then(({ data }) => {
          this.tableProperties.forEach((item, index) => {
            if (index <= 13) {
              item.subItem['disabled'] = data.form.sconDisable;
              this.componentOtherStatus = data.form.sconDisable;
            } else if (index > 13) {
              item.subItem['disabled'] = data.form.purcDisable;
              this.componentOtherStatus = data.form.purcDisable;
            }
          });
          this.btn = data.btn;
          this.subTableData = data.form.inquirySheetProcurementList;
          this.formStatus = data.form.status;
          if (data.form.status === 2) this.btn.edit = false;
          this.inquiryNumber = data.form.inquiryNumber;
          this.stffForm.stff_name = data.form.stffName;
          this.stffForm.dept_name = data.form.deptName;
          this.stffForm.dept_team_name = data.form.deptTeamName;
          this.stffForm.user_id = data.form.userId;
          this.stffForm.dept_id = data.form.deptId;
          this.stffForm.stff_id = data.form.stffId;
          this.addForm.formProperties.forEach(item => {
            item['model'] = data.form[item['prop']];
          });
          this.handleSubTableData();
        });
      }
    },
    submit() {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs.dynamicForm.$refs.form.validate(valid => {
          if (valid) {
            let formModel = this.$refs.dynamicForm.getFormModel();
            this.subTableData.forEach(item => {
              item['inquiryFactory'] = Array.from(item.tempSupp, ({ value }) => value).join(',');
              item['inquiryFactoryPrice'] = item.tempPrice.join(',');
              item['deliveryDate'] = item['deliveryDate'] instanceof Date ? item['deliveryDate'].getTime() : item['deliveryDate'];
              item['priceReviewTime'] = item['priceReviewTime'] instanceof Date ? item['priceReviewTime'].getTime() : item['priceReviewTime'];
            });
            formModel.inquirySheetProcurementList = this.subTableData;
            formModel.inquiryNumber = this.inquiryNumber;
            if (this.operation === 'add') {
              rqutAPI.addInquiryShee(formModel).then(({ data }) => {
                this.$message.success('保存成功!');
                this.jump('/rqut_edit', {
                  key: UrlEncode.encode(
                    JSON.stringify({
                      perm_id: this.$route.query.perm_id,
                      form_id: data.form_id
                    })
                  )
                });
                this.$refs.dynamicForm.reset();
                this.inquiryNumber = null;
                this.subTableData = [];
              });
            }
            if (this.operation === 'edit') {
              let id = JSON.parse(UrlEncode.decode(this.$route.query.key)).form_id;
              formModel.rqut_id = id;
              rqutAPI.editInquiryShee(formModel).then(() => {
                this.$message.success('保存成功!');
                this.isShow = true;
                this.init();
              });
            }
          } else {
            return this.$message.warning('请把必填内容补充完整');
          }
        });
      });
    },
    addCancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.isShow = false;
        this.subTableData = [];
        this.$refs.dynamicForm.reset();
        const permId = this.$route.query.perm_id;
        this.jump('/rqut_list?perm_id=' + permId);
      });
    },
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.isShow = true;
        this.init();
        this.$message.info('已撤销编辑!');
      });
    },
    delSubTableRow() {
      if (this.multiSelection.length === 0) return this.$message.warning('请至少选择一条数据!');
      let { tableData, map } = deleteTableRow('inquiryProId', this.subTableData, this.subTableMap, this.multiSelection);
      this.subTableData = tableData;
      this.subTableMap = map;
    },
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    getCustomerList() {
      getNoCatch(custAPI.getAllCustsV1, { status: 2 }).then(({ data }) => {
        this.addForm.formProperties[1].options = Array.from(data.list, ({ cust_name }) => {
          return { value: cust_name, label: cust_name };
        });
      });
    },
    async getDialog1Data() {
      await (this.dialog1Loading = true);
      await rqutAPI.getRqtaList(this.$refs.multiTable1.searchForm).then(({ data }) => {
        this.dialog1TableData = data.list;
        this.totalPage1 = data.total;
        this.dialog1Loading = false;
      });
    },
    async getDialog2Data() {
      await (this.dialog2Loading = true);
      await rqutAPI.getQutcSmpls(this.$refs.multiTable2.searchForm).then(({ data }) => {
        this.dialog2TableData = data.list;
        this.totalPage2 = data.total;
        this.dialog2Loading = false;
      });
    },
    async getDialog3Data() {
      await (this.dialog3Loading = true);
      await rqutAPI.getQutbSmpls(this.$refs.multiTable3.searchForm).then(({ data }) => {
        this.dialog3TableData = data.list;
        this.totalPage3 = data.total;
        this.dialog3Loading = false;
      });
    },
    dialog1Confirm() {
      if (this.dialog1Selection.length === 0) return this.$message.warning('请至少选择一条数据!');
      this.dialog1Selection.forEach(item => {
        this.subTableData.push({
          productName: item.smpl_name,
          productNumber: item.smpl_no,
          craftPicturesPath: item.imge_url,
          entrustedNumber: item.requ_no,
          priceReview: item.quta_price,
          inquiryRemark: item.quta_remark,
          priceReviewTime: item.quta_pridate ? new Date(item.quta_pridate * 1000) : '',
          tempSize: 1,
          tempSupp: [],
          tempPrice: []
        });
      });
      this.$refs.multiTable1.$refs.multiTable.clearSelection();
      this.dialogVisible1 = false;
    },
    dialog2Confirm() {
      if (this.dialog2Selection.length === 0) return this.$message.warning('请至少选择一条数据!');
      this.dialog2Selection.forEach(item => {
        this.subTableData.push({
          orderQuantity: item.qutb_moq,
          priceReview: item.qutb_total,
          productName: item.prod_name,
          productNumber: item.prod_no,
          craftPicturesPath: item.imge_url,
          entrustedNumber: item.requ_no,
          inquiryRemark: item.quta_remark,
          priceReviewTime: item.qutb_pridate ? new Date(item.quta_pridate * 1000) : '',
          tempSize: 1,
          tempSupp: [],
          tempPrice: []
        });
      });
      this.$refs.multiTable2.$refs.multiTable.clearSelection();
      this.dialogVisible2 = false;
    },
    dialog3Confirm() {
      if (this.dialog3Selection.length === 0) return this.$message.warning('请至少选择一条数据!');
      this.dialog3Selection.forEach(item => {
        this.subTableData.push({
          orderQuantity: item.qutc_smpl_qpc,
          productName: item.prod_name,
          productNumber: item.prod_no,
          craftPicturesPath: item.imge_url,
          entrustedNumber: item.requ_no,
          priceReview: item.qutc_smpl_price,
          inquiryRemark: item.quta_remark,
          priceReviewTime: item.qutb_pridate ? new Date(item.quta_pridate * 1000) : '',
          tempSize: 1,
          tempSupp: [],
          tempPrice: []
        });
      });
      this.$refs.multiTable3.$refs.multiTable.clearSelection();
      this.dialogVisible3 = false;
    },
    dialog1Refresh() {
      this.$refs.multiTable1.resetFields();
      this.getDialog1Data();
    },
    dialog2Refresh() {
      this.$refs.multiTable2.resetFields();
      this.getDialog2Data();
    },
    dialog3Refresh() {
      this.$refs.multiTable3.resetFields();
      this.getDialog3Data();
    },
    async getSupp() {
      await getNoCatch(suppAPI.getSuppsV1).then(({ data }) => {
        this.suppList = data;
        // this.suppList = Array.from([...data], ({ supp_id, supp_abbr }) => {
        //   return { supp_id: supp_id.toString(), supp_abbr: supp_abbr };
        // });
      });
    },
    confirmFactory(val, row) {
      row['confirmPrice'] = row['tempPrice'][row['tempSupp'].findIndex(({ value }) => value === val)];
    },
    getUnit() {
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10008 }).then(({ data }) => {
        this.unitList = Array.from(data.form.optn_cntt_list, ({ param1, param2 }) => {
          return { value: param2, label: param1 + param2 };
        });
        this.tableProperties[9].subItem.options = this.unitList;
      });
    },
    handleSubTableData() {
      this.subTableData.forEach(item => {
        this.$set(item, 'tempPrice', item.inquiryFactoryPrice.indexOf(',') !== -1 ? item.inquiryFactoryPrice.split(',') : [item.inquiryFactory]);
        let temp = [];
        if (item.inquiryFactory.indexOf(',') !== -1) {
          let split = item.inquiryFactory.split(',');
          split.forEach(subItem => {
            let { supp_id, supp_abbr } = this.suppList.find(({ supp_id }) => supp_id === Number(subItem));
            temp.push({ value: supp_id, label: supp_abbr });
          });
        } else if (item.inquiryFactory) {
          let { supp_id, supp_abbr } = this.suppList.find(({ supp_id }) => supp_id === Number(item.inquiryFactory));
          temp.push({ value: supp_id, label: supp_abbr });
        }
        this.$set(item, 'tempSupp', temp);
        this.$set(item, 'tempSize', item['tempSupp'].length);
        this.$set(item, 'confirmFactory', Number(item['confirmFactory']));
      });
    }
  }
};
</script>

<style scoped></style>
